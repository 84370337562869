<template>
  <v-app id="app">
    <!-- <vue-progress-bar></vue-progress-bar> -->
    <router-view></router-view>
  </v-app>
</template>

<script>
import RegisterStoreModule from "@/mixins/RegisterStoreModule";
import globalStore from "./store.js";

export default {
  name: "App",
  mixins: [RegisterStoreModule],
  async created() {
    await this.registerStoreModule("global", globalStore);
  },
};
</script>
<style lang="scss">
@import "@/scss/variable.scss";
@import "@/scss/app.scss";
</style>

import axios from 'axios'
import {
  cacheAdapterEnhancer
} from 'axios-extensions';

export default {
  /**
   * Make an api call to the server. Token in local storage is required
   */
  Api(token) {
    // let reqHolding = null
    const instance = axios.create({
      baseURL: `${process.env.VUE_APP_SERVER_URL}/`,
      withCredentials: false,
      headers: {
        'x-clientid': process.env.VUE_APP_SERVER_API_KEY,
        token: token || localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`),
        'Cache-Control': 'no-cache',
      },
      adapter: cacheAdapterEnhancer(axios.defaults.adapter),
    })
    // instance.interceptors.request.use(function (config) {
    //   console.log('intercept', config)
    //   // Do something before request is sent
    //   reqHolding = config
    //   return config;
    // }, function (error) {
    //   // Do something with request error
    //   return Promise.reject(error);
    // });
    // Response interceptor
    instance.interceptors.response.use((response) => {
      // console.log('Response interceptor', response.data) // eslint-disable-line
      return response
    }, (error) => {
      console.log('401 error ', error)
      if (error.response.status === 401) {
        localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`)
      }
      return Promise.reject(error.response)
    })
    return instance
  },
  // valid() {
  //   return this.Api().get('user/current')
  //     .then((response) => {
  //       if (response.status < 400) {
  //         return true
  //       }
  //       return false
  //     })
  //     .catch(() => false)
  // },
  /**
   * Check if server is available
   */
  ping() {
    return this.Api().get('')
      .then(response => response.status === 200)
      .catch((error) => {
        throw error
      })
  },
  // sendReceiptPDF(data) {
  //   return this.Api().post('/tools/email/receipt', data)
  //     .then(response => response.status === 200)
  //     .catch((error) => { throw error })
  // },
}

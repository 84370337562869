import Vue from 'vue'
import Router from 'vue-router'

// const Main = () => import('./modules/Layout/index.vue');


// import loginRoutes from '@/modules/Authentication/router'

// import homeRoutes from '@/modules/Home/router'

// import globalRoutes from '@/modules/Global/router'
// import reportViewRoutes from '@/modules/ReportView/router'
// import publicRoutes from '@/modules/Public/router'
// import expenseRoutes from '@/modules/Expense/router'
// import leadRoutes from '@/modules/Lead/router'
// import attachmentRoutes from '@/modules/Attachment/router'

// import userRoutes from '@/modules/User/router'
// import settingRoutes from '@/modules/Setting/router'
// import reportRoutes from '@/modules/Report/router'
// import adminToolsRoutes from '@/modules/AdminTools/router'


// import inventoryRoutes from '@/modules/Inventory/router'
// import customerRoutes from '@/modules/Customer/router'
// import invoiceRoutes from '@/modules/Invoice/router'
// import productRoutes from '@/modules/Product/router'


// //view order
// import remoteOrderViewRoutes from '@/modules/Order/RemoteOrderView/router'
// // import catalogOrderViewRoutes from '@/modules/Order/CatalogOrderView/router'

// // rep order
// import catalogRoutes from '@/modules/Order/Catalog/router'

// // in-house order
// import orderRoutes from '@/modules/Order/InHouse/router'

// // remote order
// import remoteOrderRoutes from '@/modules/Order/Remote/router'

// import accountRoutes from '@/modules/Account/router'


const PageNotFound = () => import('./views/PageNotFound.vue');
const Home = () => import('./views/Home.vue');
const TermsandCondition = () => import('./views/TermsandCondition.vue');
const ContactUs = () => import('./views/ContactUs.vue');
const Pricing = () => import('./views/Pricing.vue');


Vue.use(Router);

// let mainRoutes = []
// mainRoutes = mainRoutes.concat(homeRoutes)
// mainRoutes = mainRoutes.concat(settingRoutes)
// mainRoutes = mainRoutes.concat(userRoutes)
// mainRoutes = mainRoutes.concat(expenseRoutes)
// mainRoutes = mainRoutes.concat(attachmentRoutes)
// mainRoutes = mainRoutes.concat(leadRoutes)

// view in software
// mainRoutes = mainRoutes.concat(catalogOrderViewRoutes)
// mainRoutes = mainRoutes.concat(remoteOrderViewRoutes)

// // rep order
// mainRoutes = mainRoutes.concat(catalogRoutes)

// // remote order
// mainRoutes = mainRoutes.concat(remoteOrderRoutes)

// in-house Order
// mainRoutes = mainRoutes.concat(orderRoutes)


// mainRoutes = mainRoutes.concat(inventoryRoutes)
// mainRoutes = mainRoutes.concat(customerRoutes)
// mainRoutes = mainRoutes.concat(invoiceRoutes)
// mainRoutes = mainRoutes.concat(productRoutes)
// mainRoutes = mainRoutes.concat(reportRoutes)
// mainRoutes = mainRoutes.concat(adminToolsRoutes)

let routes = [
  {
    path: '/',
    name: 'website',
    component: Home,
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: ContactUs,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing,
  },
  {
    path: '/termsandcondition',
    name: 'termsandCondition',
    component: TermsandCondition,
  },
  {
    path: '*',
    name: 'pagenotfound',
    component: PageNotFound,
  },
]

// routes = routes.concat(loginRoutes)
// routes = routes.concat(publicRoutes)
// routes = routes.concat(globalRoutes)
// routes = routes.concat(reportViewRoutes)
// routes = routes.concat(remoteOrderRoutes)
// routes = routes.concat(catalogRoutes)

// routes = routes.concat(accountRoutes)


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return {
      x: 0,
      y: 0
    }
  },
})

// router.beforeEach((to, from, next) => {
//   // document.title = 'smplsale - Online Inventory Software, CRM Lead & Inventory Management, Sales Rep Software'
  
//   const loginToken = localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`)
//   if (loginToken || to.name === 'authentication-login' || to.name === 'website' || to.name === 'termsandCondition') {
//     next()
//   } else {
//     if (to.name === "home") {
//       next({
//         name: "website"
//       });
//     } else {
//       // next("/login");
//       next()
//     }
//     // next()
//   }

// })

export default router
import backofficeService from './api/backoffice'

export default {
  namespaced: true,
  state: {
    currentUser: null,
    currentBusiness: null,
    globalStatus: true,
    loginDialog: false,
    loadingStatus: {
      status: false,
      title: 'Please Wait',
    },
    type: null,
  },
  getters: {
    getType(state) {
      return state.type
    },
    loginDialog(state) {
      return state.loginDialog
    },
    globalStatus(state) {
      return state.globalStatus
    },
    loadingStatus(state) {
      return state.loadingStatus
    },
    checkRightStatus: (state) => (id) => {
      let returnValue = false
      // console.log('state.currentUser', state.currentUser)
      if (state.currentUser) {
        if (state.currentUser.isAdmin) {
          returnValue = true
        } else {
          let userRight = state.currentUser.userRights.split(",")
          userRight.map(row => {
            if (Number(row) === id) {
              returnValue = true
            }
          })
        }
      }


      return returnValue
    },
    currentUser(state) {
      return state.currentUser
    },
    currentBusiness(state) {
      return state.currentBusiness
    },
  },
  actions: {
    setGlobalStatus({
      commit
    }, payload) {
      commit('setGlobalStatus', payload)
    },
    setPageTitle(title) {
      document.title = title
    },
    setLoginDialog({
      commit
    }, payload) {
      commit('setLoginDialog', payload)
    },
    setLoadingStatus({
      commit
    }, payload) {
      commit('setLoadingStatus', payload)
    },
    initType({
      commit
    }) {
      return backofficeService.Api().get('/type')
        .then((response) => {
          commit('initType', response.data)

        })
    },
    initUser({
      commit
    }) {
      return backofficeService.Api().get('/user/current')
        .then((response) => {
          commit('initUser', response.data.user)
          commit('initBusiness', response.data.business)

        })
    },
    initBusiness({
      commit
    }, payload) {
      commit('initBusiness', payload)
    },
  },
  mutations: {
    initType(state, payload) {
      state.type = payload
    },
    setSearch(state, payload) {
      state.search = payload
    },
    initUser(state, payload) {
      state.currentUser = payload
    },
    initBusiness(state, payload) {
      state.currentBusiness = payload
    },
    setGlobalStatus(state, payload) {
      state.globalStatus = payload
    },
    setLoginDialog(state, payload) {
      state.loginDialog = payload
    },
    setLoadingStatus(state, payload) {
      state.loadingStatus = payload
    },
    initOrganization(state, payload) {
      state.currentOrganization = payload
    },
  },
}
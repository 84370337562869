import Vue from 'vue'
import vuetify from './plugins/vuetify';
import './plugins/vue-youtube'
import './plugins/style';
import VueGtag from "vue-gtag";


import App from './App.vue'
import router from './router';
import store from './plugins/vuex'

import './registerServiceWorker';

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS }  
},router);

// Vue.use(VueGtag, {
//   id: process.env.VUE_APP_GOOGLE_ANALYTICS,
//   router,
//   debug: {
//     enabled: false,
//     sendHitTask: false,
//   },
// });

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("render-event")),
}).$mount('#app')
